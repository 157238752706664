import * as actions from './ProductsActions';
import { ActionType, getType } from 'typesafe-actions';
import { List } from 'immutable';
import { Product } from './product';
import { DeliveryDate } from './deliverydate';
import { ProductDeliveryDate } from './productdeliverydate';

export interface IProductReduxState {
	products: List<Product>;
	deliveryDates: List<DeliveryDate>;
	productDeliveryDates: List<ProductDeliveryDate>;
}

export type ProductsActions = ActionType<typeof actions>;

export const initialState: IProductReduxState = {
	products: List<Product>(),
	deliveryDates: List<DeliveryDate>(),
	productDeliveryDates: List<ProductDeliveryDate>()
};

export function productsReducer(state = initialState, action: ProductsActions): IProductReduxState {
	switch (action.type) {
		case getType(actions.setProducts): {
			return handleSetProducts(state, action.payload.products);
		}
		case getType(actions.setDeliveryDate): {
			return handleSetDeliveryDates(state, action.payload.deliveryDates);
		}
		case getType(actions.setProductDeliveryDate): {
			return handleSetProductDeliveryDates(state, action.payload.productDeliveryDates);
		}
		default:
			return state;
	}
}

const handleSetProducts = (state: IProductReduxState, products: Product[]): IProductReduxState => {
	return {
		...state,
		products: List<Product>(products)
	};
};
const handleSetDeliveryDates = (state: IProductReduxState, deliveryDate: DeliveryDate[]): IProductReduxState => {
	return {
		...state,
		deliveryDates: List<DeliveryDate>(deliveryDate)
	};
};
const handleSetProductDeliveryDates = (state: IProductReduxState, productDeliveryDate: ProductDeliveryDate[]): IProductReduxState => {
	return {
		...state,
		productDeliveryDates: List<ProductDeliveryDate>(productDeliveryDate)
	};
};
