import { routerActions } from 'connected-react-router';

export const routes = {
	home: { path: '/' },
	auth: {
		register: { path: '/log-in/register' },
		login: { path: '/log-in/sign-in' }
	},
	choosePlan: {
		plan: { path: '/plan' },
		program: { path: '/plan/program' },
		review: { path: '/plan/review' },
		checkout: { path: '/plan/checkout' }
	},
	static: '/static' as const
};

export const navigationAction = {
	open: (url: string = routes.home.path, state?: any) => routerActions.push(url, state),
	goBack: () => routerActions.goBack()
};
