import { Header } from "./Header";
import { Footer } from "./Footer";
export const ErrorPage: React.FC = (props) => {
  return (
    <>
      <Header></Header>
      <main>
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="d-flex justify-content-center">
              
            </div>
            <h3>Twoja płatność się nie powiodła, prosimy o kontakt!</h3>
         
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};
