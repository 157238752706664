import { createAction } from 'typesafe-actions';
import { Product } from './product';
import { DeliveryDate } from './deliverydate';
import { ProductDeliveryDate } from './productdeliverydate';


const Definitions_SET = '@@Choinki/Products/Definitions/SET';
const DeliveryDate_SET = '@@Choinki/Products/DeliveryDate/SET';
const ProductDeliveryDate_SET = '@@Choinki/Products/ProductDeliveryDate/SET';

export const chosePlanActionsTypes = {
	definitionsSet: Definitions_SET
};

export interface ISetProductsPayload {
	products: Product[];
}
export interface ISetDeliveryDatePayload {
	deliveryDates: DeliveryDate[];
}
export interface ISetProductDeliveryDatePayload {
	productDeliveryDates: ProductDeliveryDate[];
}

export const setProducts = createAction(Definitions_SET)<ISetProductsPayload>();
export const setDeliveryDate = createAction(DeliveryDate_SET)<ISetDeliveryDatePayload>();
export const setProductDeliveryDate = createAction(ProductDeliveryDate_SET)<ISetProductDeliveryDatePayload>();
