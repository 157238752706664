import {Header} from "./Header"
import {Footer} from "./Footer"
export const Regulamin: React.FC = (props) => {
  return (
    <>
     <Header></Header>
    <main>
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="d-flex justify-content-center">
            <h5>§ 1 Postanowienia wstępne</h5>
          </div>
          <ol>
            <li>
              Sprzedaż internetowa płodów rolnych pochodzących z gospodarstwa rolnego Krzysztofa Cyniaka dostępna jest pod adresem internetowym www.choinkilodz.pl.
            </li>
            <li>
              Niniejszy Regulamin skierowany jest zarówno do Konsumentów, jak i
              do Przedsiębiorców korzystających ze Sklepu i określa zasady
              korzystania ze Sklepu internetowego oraz zasady i tryb zawierania
              Umów Sprzedaży z Klientem na odległość za pośrednictwem Sklepu.
            </li>
          </ol>
        </div>
        <div className="col-lg-8 mx-auto">
          <div className="d-flex justify-content-center">
            <h5>§ 2 Definicje</h5>
          </div>
          <ol>
            <li>
              <strong>Konsument</strong> - osoba fizyczna zawierająca ze
              Sprzedawcą umowę w ramach Sklepu, której przedmiot nie jest
              związany bezpośrednio z jej działalnością gospodarczą lub
              zawodową.
            </li>
            <li>
              <strong>Sprzedawca</strong> - osoba fizyczna prowadząca gospodarstwo rolne Krzysztof Cyniak.
            </li>
            <li>
              <strong>Klient</strong> - każdy podmiot dokonujący zakupów za
              pośrednictwem Sklepu.
            </li>
            <li>
              <strong>Przedsiębiorca</strong> - osoba fizyczna, osoba prawna i
              jednostka organizacyjna niebędąca osobą prawną, której odrębna
              ustawa przyznaje zdolność prawną, wykonująca we własnym imieniu
              działalność gospodarczą, która korzysta ze Sklepu.
            </li>
            <li>
              <strong>Sklep</strong> - sklep internetowy prowadzony przez
              Sprzedawcę pod adresem internetowym www.choinkilodz.pl .
            </li>
            <li>
              <strong>Umowa zawarta na odległość</strong> - umowa zawarta z
              Klientem w ramach zorganizowanego systemu zawierania umów na
              odległość (w ramach Sklepu), bez jednoczesnej fizycznej obecności
              stron, z wyłącznym wykorzystaniem jednego lub większej liczby
              środków porozumiewania się na odległość do chwili zawarcia umowy
              włącznie.
            </li>
            <li>
              <strong> Regulamin</strong> – niniejszy regulamin Sklepu.
            </li>
            <li>
              <strong>Zamówienie</strong> - oświadczenie woli Klienta składane
              za pomocą Formularza Zamówienia i zmierzające bezpośrednio do
              zawarcia Umowy Sprzedaży Produktu lub Produktów ze Sprzedawcą.
            </li>
            <li>
              <strong>Formularz zamówienia</strong> - interaktywny formularz
              dostępny w Sklepie umożliwiający złożenie Zamówienia poprzez
              podanie wymaganych w nim danych oraz dokonanie płatności.
            </li>
            <li>
              <strong>Operator Płatności</strong> – Cashbill S.A. z siedzibą
              przy ulicy Sobieskiego 2 w Katowicach, 40-082 Katowice, KRS
              0000323297, NIP 629-241-08-01, REGON 241048572. Podmiot
              dostarczający usługę płatności online dla Sklepu.
            </li>
            <li>
              <strong>Produkt</strong> - dostępna w Sklepie rzecz ruchoma
              (świerk srebrzysty w doniczce lub świerk srebrzysty w doniczce
              przystrojony)/usługa (dostawa na terenie Łodzi) będąca przedmiotem
              Umowy Sprzedaży między Klientem a Sprzedawcą.
            </li>
            <li>
              <strong>Umowa Sprzedaży</strong> - umowa sprzedaży Produktu
              zawierana albo zawarta między Klientem a Sprzedawcą za
              pośrednictwem Sklepu internetowego. Przez Umowę Sprzedaży rozumie
              się też - stosownie do cech Produktu - umowę o świadczenie usług i
              umowę o dzieło.
            </li>
          </ol>
        </div>
        <div className="col-lg-8 mx-auto">
          <div className="d-flex justify-content-center">
            <h5>§ 3 Kontakt ze Sklepem</h5>
          </div>
          <ol>
            <li>
              Adres Sprzedawcy: Krzysztof Cyniak, ul. Rataja 71, 92-637 Łódź
            </li>
            <li>Adres e-mail Sprzedawcy: choinkilodz@gmail.com</li>
            <li>Numer telefonu Sprzedawcy: 696 038 725</li>
            <li>
              Numer rachunku bankowego Sprzedawcy: 51 1050 1461 1000 0097 6068 5215
            </li>
            <li>
              Klient może porozumiewać się ze Sprzedawcą za pomocą adresów i
              numerów telefonów podanych w niniejszym paragrafie.
            </li>
            <li>
              Klient może porozumieć się telefonicznie ze Sprzedawcą w godzinach
              8:00- 20:00.
            </li>
          </ol>
        </div>
        <div className="col-lg-8 mx-auto">
          <div className="d-flex justify-content-center">
            <h5>§ 4 Informacje ogólne</h5>
          </div>
          <ol>
            <li>
              Sprzedawca w najszerszym dopuszczalnym przez prawo zakresie nie
              ponosi odpowiedzialności za zakłócenia w tym przerwy w
              funkcjonowaniu Sklepu spowodowane siłą wyższą, niedozwolonym
              działaniem osób trzecich lub niekompatybilnością Sklepu
              internetowego z infrastrukturą techniczną Klienta.
            </li>
            <li>
              Składanie zamówień przez Klienta na Produkty znajdujące się w
              asortymencie Sklepu możliwe jest przez podanie niezbędnych danych
              osobowych i adresowych umożliwiających realizację Zamówienia.
            </li>
            <li>
              Ceny podane w Sklepie są podane w polskich złotych i są cenami
              brutto (uwzględniają podatek VAT)
            </li>
          </ol>
        </div>
        <div className="col-lg-8 mx-auto">
          <div className="d-flex justify-content-center">
            <h5>§ 5 Zasady składania Zamówienia</h5>
          </div>
          W celu złożenia Zamówienia należy:
          <ol>
            <li>Kliknąć ikonę „Kup” na stronie Sklepu;</li>
            <li>Wybrać Produkt(y) spośród dostępnych opcji;</li>
            <li>Wybrać datę dostawy Produktu/ Produktów;</li>
            <li>Podać wymagane w Formularzu zamówienia dane;</li>
            <li>
              Dokonać płatności używając jednej z metod oferowanych przez
              Operatora Płatności.
            </li>
          </ol>
        </div>
        <div className="col-lg-8 mx-auto">
          <div className="d-flex justify-content-center">
            <h5>§ 6 Oferowane metody dostawy oraz płatności</h5>
          </div>
          Klient może skorzystać z następujących metod płatności:
          <ol>
            <li>Płatności elektroniczne</li>
          </ol>
          Klient może skorzystać z następujących metod dostawy:
          <ol>
            <li>Dostawa pod wskazany w Formularzu adres.</li>
          </ol>
        </div>
        <div className="col-lg-8 mx-auto">
          <div className="d-flex justify-content-center">
            <h5>§ 7 Wykonanie umowy sprzedaży</h5>
          </div>
          <ol>
            <li>
              Zawarcie Umowy Sprzedaży między Klientem a Sprzedawcą następuje po
              uprzednim złożeniu przez Klienta Zamówienia za pomocą Formularza
              zamówienia w Sklepie internetowym zgodnie z § 5 Regulaminu.
            </li>
            <li>Umową Sprzedaży objęte są żywe Produkty.</li>
            <li>
              Po złożeniu Zamówienia Sprzedawca niezwłocznie potwierdza jego
              otrzymanie oraz jednocześnie przyjmuje Zamówienie do realizacji.
              Potwierdzenie otrzymania Zamówienia i jego przyjęcie do realizacji
              następuje poprzez przesłanie przez Sprzedawcę Klientowi stosownej
              wiadomości e-mail na podany w trakcie składania Zamówienia adres
              poczty elektronicznej Klienta. Z chwilą otrzymania przez Klienta
              powyższej wiadomości e-mail zostaje zawarta Umowa Sprzedaży między
              Klientem a Sprzedawcą.
            </li>
            <li>
              Dostawa na terenie miasta Łodzi odbywa się gratis, nie stanowi dla
              Klienta dodatkowych kosztów.
            </li>
            <li>
              Termin dostawy do wyboru podczas zakupu Produktów poprzez
              Formularz
              <ul>
                <li>02.12.2023</li>
                <li>05.12.2023</li>
                <li>09.12.2023</li>
                <li>16.12.2023</li>
                <li>20.12.2023</li>
                <li>23.12.2023</li>
              </ul>
            </li>

            <li>
              Dostawy do Kupujących będących Konsumentami będą realizowane w
              godzinach 8-20.
            </li>
            <li>
              Klient zostanie poinformowany o przewidywanej przybliżonej godzinie
              dostawy.
            </li>
            <li>
              Istnieje możliwości dostawy we wcześniejszych godzinach po
              uprzednim kontakcie ze strony Kupującego i indywidualnym
              ustaleniu- OPCJA DOSTĘPNA WYŁĄCZNIE DLA KUPUJĄCYCH BĘDĄCYCH
              PRZEDSIĘBIORCAMI..
            </li>
            <li>
              Dostawa Produktu odbywa się wyłącznie na terenie miasta Łodzi.
            </li>
          </ol>
        </div>
        <div className="col-lg-8 mx-auto">
          <div className="d-flex justify-content-center">
            <h5>§ 8 Prawo odstąpienia od umowy</h5>
          </div>
          <ol>
            <li>
              Klient może odstąpić od umowy w terminie 14 dni od daty zakupu
              Produktu jednakże nie później niż nastąpi dostawa i pokwitowanie
              odbioru Produktu pod wskazanym w Formularzu zamówienia adresem-
              żywe rośliny nie podlegają zwrotowi.
            </li>
            <li>
              Klient może odstąpić od Umowy, składając Sprzedawcy oświadczenie o
              odstąpieniu od Umowy.
            </li>
            <li>
              Oświadczenie może być wysłane za pomocą tradycyjnej poczty, bądź
              drogą elektroniczną poprzez przesłanie oświadczenia z podpisem na
              adres e-mail Sprzedawcy - dane kontaktowe Sprzedawcy zostały
              określone w § 3. Oświadczenie można złożyć również na formularzu,
              którego wzór stanowi załącznik do ustawy z dnia 30 maja 2014 roku
              o prawach konsumenta, jednak nie jest to obowiązkowe.
            </li>
            <li>
              Skutki odstąpienia od Umowy:
              <ul>
                <li>
                  a. W przypadku odstąpienia od Umowy zawartej na odległość
                  Umowę uważa się za niezawartą.
                </li>
                <li>
                  b. W przypadku odstąpienia od Umowy Sprzedawca zwraca
                  Klientowi niezwłocznie, nie później niż w terminie 14 dni od
                  dnia otrzymania oświadczenia Klienta o odstąpieniu od Umowy,
                  wszystkie dokonane przez niego płatności.
                </li>
                <li>
                  c. Zwrotu płatności Sprzedawca dokona przy użyciu takich
                  samych metod płatności, jakie zostały przez Klienta użyte w
                  pierwotnej transakcji.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div className="col-lg-8 mx-auto">
          <div className="d-flex justify-content-center">
            <h5>§ 9 Reklamacja</h5>
          </div>
          <ol>
            <li>Umową Sprzedaży objęte są żywe Produkty.</li>
            <li>
              Reklamację należy zgłosić pisemnie lub drogą elektroniczną na
              podane w niniejszym Regulaminie adresy Sprzedawcy.
            </li>
            <li>
              Podstawą zgłoszenia reklamacji jest dokument zakupu przedstawiony
              przez Klienta
            </li>
            <li>
              Sprzedający rozpatruje reklamacje najpóźniej w ciągu 14 dni
              roboczych od ich wpłynięcia.
            </li>
          </ol>
        </div>
        <div className="col-lg-8 mx-auto">
          <div className="d-flex justify-content-center">
            <h5>§ 10 Dane osobowe w Sklepie internetowym</h5>
          </div>
          <ol>
            <li>
              Administratorem danych osobowych Klientów zbieranych za
              pośrednictwem Sklepu internetowego jest Sprzedawca.
            </li>
            <li>
              Klienci powierzają Sprzedawcy następujące dane osobowe:
              <ol>
                <li>Imię i nazwisko</li>
                <li>
                  Adres dostawy/faktury obejmujący: ulicę, kod pocztowy,
                  miejscowość, kraj, nazwę firmy,{" "}
                </li>
                <li>Adres e-mail</li>
                <li>Numer rachunku bankowego</li>
                <li>Numer telefonu</li>
                <li>NIP</li>
              </ol>
            </li>
            <li>
              Dane osobowe Klientów zbierane przez administratora za
              pośrednictwem Sklepu internetowego zbierane są w celu realizacji
              Umowy Sprzedaży, a jeżeli Klient wyrazi na to zgodę - także w celu
              marketingowym.
            </li>
            <li>
              Odbiorcą danych osobowych i podmiotem, któremu sprzedawca
              udostępnia powierzone dane Klientów Sklepu internetowego jest
              Operator Płatności
            </li>
            <li>
              Odbiorcami danych osobowych Klientów Sklepu internetowego mogą
              być:
              <ul>
                <li>
                  W przypadku Klienta, który korzysta w Sklepie internetowym ze
                  sposobu dostawy przesyłką pocztową lub przesyłką kurierską,
                  Administrator udostępnia zebrane dane osobowe Klienta
                  wybranemu przewoźnikowi lub pośrednikowi realizującemu
                  przesyłki na zlecenie Administratora.
                </li>
                <li>
                  W przypadku Klienta, który korzysta w Sklepie internetowym ze
                  sposobu płatności elektronicznych lub kartą płatniczą
                  Administrator udostępnia zebrane dane osobowe Klienta,
                  wybranemu podmiotowi obsługującemu powyższe płatności w
                  Sklepie internetowym.
                </li>
              </ul>
            </li>
            <li>
              Klient ma prawo dostępu do treści swoich danych oraz ich
              poprawiania.
            </li>
            <li>
              Podanie danych osobowych jest dobrowolne, aczkolwiek niepodanie
              wskazanych w Regulaminie danych osobowych niezbędnych do zawarcia
              Umowy Sprzedaży skutkuje brakiem możliwości zawarcia tejże umowy.
            </li>
          </ol>
        </div>
        <div className="col-lg-8 mx-auto">
          <div className="d-flex justify-content-center">
            <h5>§ 11 Postanowienia końcowe</h5>
          </div>
          <ol>
            <li>
              Umowy zawierane poprzez Sklep internetowy zawierane są w języku
              polskim.
            </li>
            <li>
              Sprzedawca zastrzega sobie prawo do dokonywania zmian Regulaminu z
              ważnych przyczyn to jest: zmiany przepisów prawa, zmiany sposobów
              płatności i dostaw - w zakresie, w jakim te zmiany wpływają na
              realizację postanowień niniejszego Regulaminu. Zamówienia złożone
              przed datą wprowadzenia zmian do niniejszego Regulaminu są
              realizowane na podstawie zapisów obowiązujących w dniu złożenia
              zamówienia.
            </li>
            <li>
              W sprawach nieuregulowanych w niniejszym Regulaminie mają
              zastosowanie powszechnie obowiązujące przepisy prawa polskiego, w
              szczególności: Kodeksu cywilnego; ustawy o świadczeniu usług drogą
              elektroniczną; ustawy o prawach konsumenta, ustawy o ochronie
              danych osobowych.
            </li>
            <li>
              Klient ma prawo skorzystać z pozasądowych sposobów rozpatrywania
              reklamacji i dochodzenia roszczeń. W tym celu może złożyć skargę
              za pośrednictwem unijnej platformy internetowej ODR dostępnej pod
              adresem: http://ec.europa.eu/consumers/odr/.
            </li>
            <li>
              Sprzedający nie ponosi odpowiedzialności za przyjęcie się drzewek
              wysadzonych do gruntu , wzrost oraz ukorzenienie się zakupionych
              roślin, ponieważ związane jest to z czynnikami zewnętrznymi nie
              zależnymi od niego i nie podlegającymi jego kontroli .
            </li>
          </ol>
        </div>
      </div>
    </main>
      <Footer></Footer>
    </>
  );
};
