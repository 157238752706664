import React from "react";
import { Link } from "react-router-dom";

export const Footer: React.FC = (props) => {
    return (
      <footer className="pt-4 my-md-5 pt-md-5 border-top">
      <div className="row">
        <div className="col-12 col-md">
          
          <small className="d-block mb-3 text-muted">© 2020–2021</small>
        </div>
        <div className="col-6 col-md">
          <h5>Dokumenty</h5>
          <ul className="list-unstyled text-small">
            
          <li className="mb-1"> <Link className="link-secondary text-decoration-none" to="/dostawa">Dostawa</Link></li>
          <li className="mb-1">  <Link className="link-secondary text-decoration-none" to="/kontakt">Kontakt</Link></li>
          <li className="mb-1">  <Link className="link-secondary text-decoration-none" to="/klauzula">Klauzula&nbsp;Informacyjna</Link></li>
          <li className="mb-1">  <Link className="link-secondary text-decoration-none" to="/Regulamin">Regulamin</Link></li>
          </ul>
        </div>
        <div className="col-6 col-md">
        </div>
        <div className="col-6 col-md">
          <h5>Social Media</h5>
          <ul className="list-unstyled text-small">
            <li className="mb-1"><a className="link-secondary text-decoration-none" href="https://www.facebook.com/ChoinkiLodz">Facebook</a></li>
            <li className="mb-1"><a className="link-secondary text-decoration-none" href="https://www.instagram.com/choinki_lodz/">Instagram</a></li>
          </ul>
        </div>
      </div>
    </footer>
    );
  };
  