import { Header } from "./Header";
import { Footer } from "./Footer";
export const Okej: React.FC = (props) => {
  return (
    <>
      <Header></Header>
      <main>
      <div className="d-flex p-2 justify-content-center">
      <div className="p-2 justify-content-center" style={{textAlign:"center"}}>  
            <h3>Dziękujemy za dokonanie zakupu!</h3>
            <p>Dostawy do Kupujących będących Konsumentami będą realizowane w godzinach 8:00- 20:00.</p>
         </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};
