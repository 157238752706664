import { actions } from "react-redux-toastr";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import * as orderActions from "./OrderActions";
import { navigationAction, routes } from "../router/router-actions";
import { CustomerCreateResponse } from "./CustomerCreateResponse";
import { Customer } from "./Customer";
import { OrderPersonData } from "./OrderPersonData";
import { OrderCreateResponse } from "./OrderCreateResponse";
import { DiscountResponse } from "./DiscountResponse";
import axios from "axios";
import { Discount } from "./Discount";
import {OrderResponseData} from "./OrderResponseData"
import {OrderExportResponseData} from "./OrderExportResponseData"

export const createCustomer =
  (customer: Customer): ThunkAction<any, RootState, any, any> =>
  (dispatch, getState) => {
    const store: RootState = getState();
    //create customer

    axios
      .post<CustomerCreateResponse>(
        "https://choinkilodz.pl/API_Restful/api/customer/",
        customer
      )
      .then((x) => {
        dispatch(
          orderActions.setOrderPersonData({
            ordererData: { ...customer, id: x.data.customerId },
          })
        );
        //crete order and retreive it
        axios
          .post("https://choinkilodz.pl/API_Restful/api/order/", {
            clientid: x.data.customerId,
            discountcode: store.order.orderData.discountcode
          })
          .then((y) => {
            axios
              .get<OrderCreateResponse>(
                "https://choinkilodz.pl/API_Restful/api/order/" + y.data.orderId
              )
              .then((z) => {
                dispatch(
                  orderActions.setOrderData({ orderData: z.data.order[0] })
                );
                //crete order products
                var products = store.order.products.toArray().map((prod) => {
                  return {
                    ...prod,
                    clientorderid: y.data.orderId,
                    discountcode: "",
                  };
                });

                var promises = products.map((pushproduct) =>
                  axios.post(
                    "https://choinkilodz.pl/API_Restful/api/orderproducts/",
                    pushproduct
                  )
                );
                Promise.all(promises).then((update) => {
                  ///order products added , now we can recalc order
                  dispatch(
                    orderActions.setOrderData({ orderData: z.data.order[0] })
                  );
                  axios
                    .put(
                      "https://choinkilodz.pl/API_Restful/api/order/" +
                        y.data.orderId,
                      {}
                    )
                    .then((updated) => {

                      axios
                      .get<OrderResponseData>("https://choinkilodz.pl/API_Restful/api/order/"+y.data.orderId).then(order=>{
                        if(order.data.order[0].booked==1){

                          axios
                          .post("https://choinkilodz.pl/API_Restful/api/payment/"+y.data.orderId,{}).then(payment=>{

                            axios
                            .put("https://choinkilodz.pl/API_Restful/api/payment/"+y.data.orderId,{ cashbilId: payment.data.paymentId}).then(or2=>{
                              window.location.replace(payment.data.redirectUrl)
                            })
                          })

                        }
                      })
                      
                    });
                });
              });
          });
      });
  };

export const loadDiscount =
  (code: string): ThunkAction<any, RootState, any, any> =>
  (dispatch, getState) => {
    const store: RootState = getState();
    //create customer

    axios
      .get<DiscountResponse>(
        "https://choinkilodz.pl/API_Restful/api/discount/" + code
      )
      .then((x) => {
        if (x.data.status == 200) {
          var data =x.data.discount[0];
          dispatch(
            orderActions.setDiscount({ discount: { ...data } })
          );
        }
        else{
          dispatch(
            orderActions.setDiscount({ discount: {  } as Discount})
          );
        }
      });
  };

  export const loadOrders =
  (password: string): ThunkAction<any, RootState, any, any> =>
  (dispatch, getState) => {
    const store: RootState = getState();
    //create customer

    axios
      .post<OrderExportResponseData>(
        "https://choinkilodz.pl/API_Restful/api/export/" ,{ password: password }
      )
      .then((x) => {
          dispatch(
            orderActions.setExportData({orders: x.data.orders})
          );
       
      });
  };
