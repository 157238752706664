import { createAction } from 'typesafe-actions';
import { Discount } from './Discount';
import { Order } from './Order';
import { OrderPersonData } from './OrderPersonData';
import { OrderProduct } from './OrderProduct';
import { OrderReport } from './OrderReport';


const PreAddProduct_SET = '@@ChoinkiLodz/Product/ADD';
const AddProduct_SET = '@@ChoinkiLodz/Order/Product/ADD';
const RemoveProduct_SET = '@@ChoinkiLodz/Order/Product/Remove';
const OrderPersonData_SET = '@@ChoinkiLodz/Order/OrderPersonData/SET';
const OrderData_SET = '@@ChoinkiLodz/Order/OrderData/SET';
const Discount_SET = '@@ChoinkiLodz/Order/Discount/SET';
const Export_SET = '@@ChoinkiLodz/Order/Export/SET';



export const orderActionsTypes = {
	addProductSet: AddProduct_SET
};

export interface ISetOrderProductPayload {
	products: OrderProduct;
}
export interface ISetPreSelectProductPayload {
	productId: number;
	productName:string;
}

export interface IRemoveOrderProductPayload {
	productId: number;
	deliveryDateId: number;
}

export interface ISetOrderPersonDataPayload {
	ordererData: OrderPersonData;
}
export interface ISetOrderDataPayload {
	orderData: Order;
}
export interface ISetDiscountPayload {
	discount: Discount;
}
export interface ISetExportPayload {
	orders: OrderReport[];
}

export const setAddProduct = createAction(AddProduct_SET)<ISetOrderProductPayload>();
export const setPreAddProduct = createAction(PreAddProduct_SET)<ISetPreSelectProductPayload>();

export const setRemoveProduct = createAction(RemoveProduct_SET)<IRemoveOrderProductPayload>();
export const setOrderPersonData = createAction(OrderPersonData_SET)<ISetOrderPersonDataPayload>();
export const setOrderData = createAction(OrderData_SET)<ISetOrderDataPayload>();
export const setDiscount = createAction(Discount_SET)<ISetDiscountPayload>();
export const setExportData = createAction(Export_SET)<ISetExportPayload>();

