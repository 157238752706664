import { actions } from "react-redux-toastr";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
// import { GetApiConfig } from '../api-client-config';
import * as productsActions from "./ProductsActions";
import { navigationAction, routes } from "../router/router-actions";
import { Product, ProductResponse } from "./product";
import { DeliveryDateResponse } from "./deliverydate";
import { ProductDeliveryDateResponse } from "./productdeliverydate";
import { Customer } from "../order/Customer";
import axios from "axios"

export const loadProducts =
  (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
    const store: RootState = getState();

    axios.get<ProductResponse>("https://choinkilodz.pl/API_Restful/api/product/").then(x=>{
      dispatch(productsActions.setProducts({ products: x.data.products }));
    })

  
  };

export const loadDeliveryDate =
  (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
    const store: RootState = getState();

    
    axios.get<DeliveryDateResponse>("https://choinkilodz.pl/API_Restful/api/deliverydate/").then(x=>{
      dispatch(productsActions.setDeliveryDate({ deliveryDates: x.data.deliveryDate }));
    })
  };
export const loadProductDeliveryDate =
  (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
    const store: RootState = getState();
    
    
    axios.get<ProductDeliveryDateResponse>("https://choinkilodz.pl/API_Restful/api/productdeliverydate/").then(x=>{
      dispatch(productsActions.setProductDeliveryDate({ productDeliveryDates: x.data.productDeliveryDate }));
    })

  };
