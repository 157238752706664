import {
	configureStore,
	ThunkAction,
	Action,
	combineReducers,
	getDefaultMiddleware
} from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import appHistory from './app-history';
import { productsReducer } from './products/ProductsReducer';
import { orderReducer } from './order/OrderReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';

export const rootReducer = combineReducers({
	order: orderReducer,
	products: productsReducer,
	router: connectRouter(appHistory),
	toastr: toastrReducer
});

// const persistedState = localStorage.getItem('reduxState')
// 	? JSON.parse(localStorage.getItem('reduxState')!)
// 	: {};

export const store = configureStore({
	devTools: true,
	reducer: rootReducer,
	
	middleware: [
		...getDefaultMiddleware({
			serializableCheck: false
		}),
		routerMiddleware(appHistory),
		thunk
	]
});

// store.subscribe(() => {
// 	localStorage.setItem('reduxState', JSON.stringify(store.getState()));
// });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
