import { IProductReduxState } from "./ProductsReducer";
import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { List } from "immutable";
import { Product } from "./product";
import { ProductDeliveryDate } from "./productdeliverydate";
import { DeliveryDate } from "./deliverydate";

export const productsStateSelector = createSelector(
  (state: RootState) => state.products,
  (products) => products
);

export const getAllProducts = createSelector(
  productsStateSelector,
  (chosePlanState: IProductReduxState): List<Product> => {
    return chosePlanState.products;
  }
);

export const getAllProductsDeliveryDates = createSelector(
  productsStateSelector,
  (chosePlanState: IProductReduxState): List<ProductDeliveryDate> => {
    return chosePlanState.productDeliveryDates;
  }
);

export const getAllDeliveryDates = createSelector(
  productsStateSelector,
  (chosePlanState: IProductReduxState): List<DeliveryDate> => {
    return chosePlanState.deliveryDates;
  }
);

export const getAllProductsAvailable = createSelector(
  getAllProducts,
  getAllProductsDeliveryDates,
  getAllDeliveryDates,
  (
    product: List<Product>,
    productsDeliveryDates: List<ProductDeliveryDate>,
    deliveryDates: List<DeliveryDate>
  ): List<ProductDeliveryDate & DeliveryDate & Product> => {
    const productArray = product;
    const productsDeliveryDatesArray = productsDeliveryDates;
    const deliveryDatesArray = deliveryDates;
    var res = productsDeliveryDatesArray
      .toArray()
      .filter((x) => x.qty > 0)
      .map(
        (itm) =>
          ({
            ...productArray
              .toArray()
              .find((p) => p.qty > 0 && p.id === itm.productid && p),
            ...deliveryDatesArray
              .toArray()
              .find((d) => d.id === itm.deliverydateid  && d ),
            ...itm,
          } as ProductDeliveryDate & DeliveryDate & Product)
      );
    return List<ProductDeliveryDate & DeliveryDate & Product>(res);
  }
);
