import { Header } from "./Header";
import { Footer } from "./Footer";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { OrderReport } from "./business/order/OrderReport";

export interface ExportToExcelProps{
    apiData: OrderReport[]; fileName:string;
}

export const ExportToExcel : React.FC<ExportToExcelProps> = props => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData: OrderReport[], fileName :string) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button onClick={(e) => exportToCSV(props.apiData, props.fileName)}>Export</button>
  );
};