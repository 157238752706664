import { Header } from "./Header";
import { Footer } from "./Footer";

import ConfettiExplosion from "@reonomy/react-confetti-explosion";
import { CSSProperties, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductsAvailable } from "./business/products/ProductsSelector";
import {
  getFinalPrice,
  getOrder,
  getOrdererData,
  getPreSelectedProduct,
  getSelectedProduct,
  getSelectedProductPrice,
} from "./business/order/OrderSelector";
import { setAddProduct, setOrderData, setRemoveProduct } from "./business/order/OrderActions";
import { createCustomer, loadDiscount } from "./business/order/OrderEpics";

export const Form: React.FC = (props) => {
  let dispatch = useDispatch();
  let available = useSelector(getAllProductsAvailable);
  let selectedProducts = useSelector(getSelectedProduct);
  let orderer = useSelector(getOrdererData);
  let order = useSelector(getOrder);
  let price = useSelector(getSelectedProductPrice);
  let finalPrice = useSelector(getFinalPrice);

  let preSelectedProduct = useSelector(getPreSelectedProduct);
useEffect(()=>{
  
  setSelectedProduct(preSelectedProduct.productId);
  setSelectedQty(1);
},[preSelectedProduct])
  let removeProductOrder = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    productId: number,
    deliveryDate: number
  ): void => {
    e.preventDefault();
    dispatch(setRemoveProduct({ productId, deliveryDateId: deliveryDate }));
  };

  let addProductOrder = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    if (selectedDate == -1 || selectedProduct == -1 || selectedQty == -1) {
      alert("podaj produkt, ilość oraz datę dostawy");
      return;
    }
    var key = available.find(
      (x) => x.productid == selectedProduct && x.deliverydateid == selectedDate
    );
    dispatch(
      setAddProduct({
        products: {
          qty: selectedQty,
          deliveryDate: selectedDate,
          productId: selectedProduct,
          deliveryDateName: key?.date!,
          productName: key?.name!,
          price: key?.price!,
        },
      })
    );
    setSelectedDate(-1);
    setSelectedProduct(-1);
    setSelectedQty(-1);
  };

  let addPromoCode = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
   
    dispatch(
      loadDiscount(orderData.discountcode)
    );
  };
  const [selectedProduct, setSelectedProduct] = useState(-1);
  const [selectedDate, setSelectedDate] = useState(-1);
  const [selectedQty, setSelectedQty] = useState(-1);
  const [ordererData, setOrdererData] = useState(orderer);
  const [orderData, setOrderData] = useState(order);

  let ilosc = Math.max.apply(
    Math,
    available
      .toArray()
      .filter(
        (x) =>
          x.productid == selectedProduct &&
          (x.deliverydateid == selectedDate || selectedDate == -1)
      )
      .filter(
        (thing, i, arr) => arr.findIndex((t) => t.qty === thing.qty) === i
      )
      .map((x) => {
        return x.qty;
      })
  );
  let ilosci: number[] = [];
  for (let i = 1; i <= ilosc && i <= 5; i++) {
    ilosci = [...ilosci, i];
  }
  return (
    <>
      <Header></Header>
      <main>
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h3>Zamówienie:</h3>
            <div className="d-flex justify-content-center">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Produkt</th>
                    <th scope="col">Ilość</th>
                    <th scope="col">Termin Dostawy</th>
                    <th scope="col">Akcja</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProducts.map((x) => {
                    return (
                      <tr key={"row_" + x.productId + "_" + x.deliveryDate}>
                        <th scope="row">{x.productId}</th>
                        <td>{x.productName}</td>
                        <td>{x.qty}</td>
                        <td>{x.deliveryDateName}</td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={(e) =>
                              removeProductOrder(e, x.productId, x.deliveryDate)
                            }
                          >
                            Usuń
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  <tr >
                    <th scope="row"  className="align-middle">🎁</th>
                    <td  className="align-middle">
                      <select
                        className="form-control"
                        id="produkt"
                        name="produkt"
                        value={selectedProduct}
                        onChange={(e) => {
                          setSelectedProduct(+e.target.value);
                        }}
                      >
                        <option selected value={-1}>
                          --wybierz--
                        </option>
                        {available
                          .toArray()
                          .map((x) => {
                            return { id: Number(x.productid), label: x.name };
                          })
                          .filter(
                            (thing, i, arr) =>
                              arr.findIndex((t) => t.id === thing.id) === i
                          )
                          .filter(
                            (x) =>
                              selectedProducts.find(
                                (z) => z.productId == x.id
                              ) === undefined
                          )
                          .map((x) => (
                            <option key={"produkt_" + x.id} value={x.id}>
                              {x.label}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td className="align-middle">
                      <select
                        className="form-control"
                        id="ilosc"
                        name="ilosc"
                        value={selectedQty}
                        onChange={(e) => {
                          setSelectedQty(+e.target.value);
                        }}
                      >
                        <option selected value={-1}>
                          --wybierz--
                        </option>
                        {ilosci.map((x) => (
                          <option key={"ilosc_" + x} value={x}>
                            {x}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="align-middle">
                      <select
                        className="form-control"
                        id="termin"
                        name="dostawa"
                        onChange={(e) => {
                          setSelectedDate(+e.target.value);
                        }}
                      >
                        <option selected value={-1}>
                          --wybierz--
                        </option>
                        {available
                          .toArray()
                          .filter((x) => x.productid == selectedProduct)
                          .map((x) => {
                            return {
                              id: Number(x.deliverydateid),
                              label: x.date,
                            };
                          })
                          .filter(
                            (thing, i, arr) =>
                              arr.findIndex((t) => t.id === thing.id) === i
                          )
                          .map((x) => (
                            <option key={"data_" + x.id} value={x.id}>
                              {x.label}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td className="align-middle">
                      <button
                        className="btn btn-success"
                        onClick={(e) => {
                          addProductOrder(e);
                        }}
                      >
                        Dodaj
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h3>Podsumowanie:</h3>
            <div className="d-flex justify-content-center">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Cena</th>
                    <th scope="col">Kod promo</th>
                    <th scope="col">Zastosuj</th>
                    <th scope="col">Cena końcowa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td  className="align-middle"><strong>{price} PLN</strong></td>
                    <td className="align-middle"><input
                  type="text"
                  className="form-control"
                  id="promocode"
                  name="promocode"
                  required
                  placeholder="XXXXX"
                  value={orderData.discountcode || ""}
                  onChange={(e) => {
                    setOrderData({
                      ...orderData,
                      discountcode: e.currentTarget.value,
                    });
                  }}
                /></td>
                    <td className="align-middle">
                      <button
                        className="btn btn-success"
                        onClick={(e) => {
                          addPromoCode(e);
                        }}
                      >
                        Użyj
                      </button>
                    </td>
                    <td className="align-middle"><h4 style={{color:"red  "}}>{finalPrice.toFixed(2)} PLN</h4></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        <div className="row">
          <div className="col-lg-8 mx-auto">
          <form
            id="registration"
            name="registration"
            onSubmit={(e) => e.preventDefault()}
          >
            <div>
              <h3>Dane odbiorcy:</h3>
              <br />
              <div className="form-group">
                <label htmlFor="nazwa">Imię i Nazwisko:</label>
                <input
                  type="text"
                  className="form-control"
                  id="nazwa"
                  name="nazwa"
                  required
                  placeholder="Imię i Nazwisko"
                  value={ordererData.name || ""}
                  onChange={(e) => {
                    setOrdererData({
                      ...ordererData,
                      name: e.currentTarget.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputAddress">Nip:</label>
                <input
                  type="text"
                  className="form-control"
                  id="nip"
                  name="nip"
                  placeholder="NIP"
                  value={ordererData.nip || ""}
                  onChange={(e) => {
                    setOrdererData({
                      ...ordererData,
                      nip: e.currentTarget.value,
                    });
                  }}
                />
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="ulica">Ulica:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="ulica"
                    name="ulica"
                    placeholder="Ulica"
                    required
                    value={ordererData.street || ""}
                    onChange={(e) => {
                      setOrdererData({
                        ...ordererData,
                        street: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="nrbudynku">Nr Budynku:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="nrbudynku"
                    name="nrbudynku"
                    placeholder="Nr"
                    required
                    value={ordererData.buildingNumber || ""}
                    onChange={(e) => {
                      setOrdererData({
                        ...ordererData,
                        buildingNumber: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="nrlokalu">Lokal:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="nrlokalu"
                    name="nrlokalu"
                    placeholder="Lokal"
                    value={ordererData.roomNumber || ""}
                    onChange={(e) => {
                      setOrdererData({
                        ...ordererData,
                        roomNumber: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="miasto">Miasto</label>
                <input
                  type="text"
                  className="form-control"
                  id="miasto"
                  name="miasto"
                  placeholder="Łódź"
                  disabled
                  value={ordererData.city || ""}
                  onChange={(e) => {
                    setOrdererData({
                      ...ordererData,
                      city: e.currentTarget.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="miasto">Kod Pocztowy</label>
                <input
                  type="text"
                  className="form-control"
                  id="kodpocztowy"
                  name="kodpocztowy"
                  required
                  placeholder="XX-XXX"
                  value={ordererData.postalCode || ""}
                  onChange={(e) => {
                    setOrdererData({
                      ...ordererData,
                      postalCode: e.currentTarget.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="telefon">Telefon</label>
                <input
                  type="text"
                  className="form-control"
                  id="telefon"
                  name="telefon"
                  placeholder="nr telefonu"
                  required
                  value={ordererData.phone || ""}
                  onChange={(e) => {
                    setOrdererData({
                      ...ordererData,
                      phone: e.currentTarget.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Mail</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  required
                  name="email"
                  placeholder="twoj@mail.pl"
                  value={ordererData.mail || ""}
                  onChange={(e) => {
                    setOrdererData({
                      ...ordererData,
                      mail: e.currentTarget.value,
                    });
                  }}
                />
              </div>

              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    name="gridCheck"
                    required
                    checked={ordererData.accepted}
                    onChange={(e) => {
                      setOrdererData({
                        ...ordererData,
                        accepted: e.currentTarget.checked,
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor="gridCheck">
                    Wyrażam zgodę na przetwarzanie moich danych osobowych,
                    podanych w niniejszym formularzu, w celu realizacji
                    złożonego zamówienia. Oświadczam, że zapoznałem się z&nbsp;
                    <Link to="/klauzula">klauzulą informacyjną</Link>i
                    zostałem(am) poinformowany(a) o przysługującym mi prawie
                    dostępu do treści moich danych osobowych oraz ich
                    poprawiania oraz żądania usunięcia, jak również prawa do
                    wycofania zgody w każdym czasie. Ze wskazanych uprawnień
                    mogę skorzystać pisząc na adres: Krzysztof Cyniak z
                    siedzibą w Łodzi, ul. Rataja 71, 92- 637 Łódź lub wysyłając email na adres:
                    choinkilodz@gmail.com
                  </label>
                </div>
              </div>
              <button
                id="kupuj"
                className="btn btn-danger btn btn-rounded"
                type="submit"
                disabled = {selectedProducts.size==0}
                onClick={(e) => dispatch(createCustomer({ ...ordererData }))}
              >
                Dokonaj zakupu
              </button>
            </div>
          </form>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};
