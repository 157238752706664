import { IOrderReduxState } from "./OrderReducer";
import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { List } from "immutable";
import { OrderProduct } from "./OrderProduct";
import { OrderPersonData } from "./OrderPersonData";
import { Order } from "./Order";
import { OrderReport } from "./OrderReport";

export const orderStateSelector = createSelector(
  (state: RootState) => state.order,
  (orderState) => orderState
);

export const getOrdererData = createSelector(
  orderStateSelector,
  (orderState: IOrderReduxState): OrderPersonData => {
    return orderState.orderPersonData;
  }
);

export const getSelectedProduct = createSelector(
	orderStateSelector,
	(orderState: IOrderReduxState): List<OrderProduct> => {
	  return orderState.products;
	}
  );
  export const getPreSelectedProduct = createSelector(
	orderStateSelector,
	(orderState: IOrderReduxState): OrderProduct => {
	  return orderState.preSelectedProduct;
	}
  );

export const getSelectedProductPrice = createSelector(
	orderStateSelector,
	(orderState: IOrderReduxState): number => {
	  if (orderState.products?.toArray().length > 0) {
		return orderState.products
		  .toArray()
		  .map((x) => x.price * x.qty)
		  .reduce((prev, current) => prev + current);
	  }
	  return 0;
	}
  );

  export const getFinalPrice = createSelector(
	orderStateSelector,
	(orderState: IOrderReduxState): number => {
	  if (orderState.products?.toArray().length > 0) {
		var price= orderState.products
		  .toArray()
		  .map((x) => x.price * x.qty)
		  .reduce((prev, current) => prev + current);

		  var discountPercents = orderState.discount.discountinpercents??0
		  var discount = price*(discountPercents/100)
		  return price-discount;
	  }
	  return 0;
	}
  );

  export const getOrder = createSelector(
	orderStateSelector,
	(orderState: IOrderReduxState): Order => {
	  return orderState.orderData;
	}
  );

  export const getOrderList = createSelector(
	orderStateSelector,
	(orderState: IOrderReduxState): List<OrderReport> => {
	  return orderState.orders;
	}
  );
	