import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import appHistory from "./business/app-history";
import { LandingPage } from "./LandingPage";
import { Regulamin } from "./Regulamin";

import { Klauzula } from "./Klauzula";
import { Kontakt } from "./Kontakt";
import { Dostawa } from "./Dostawa";
import { Form } from "./Form";
import {Okej } from "./Okej"
import {ErrorPage} from "./ErrorPage"
import {Export} from "./Export"
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  loadDeliveryDate,
  loadProductDeliveryDate,
  loadProducts,
} from "./business/products/ProductsEpics";
import { useLocation } from "react-router-dom";

export  function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const App: React.FC = (props) => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProducts());
    dispatch(loadDeliveryDate());
    dispatch(loadProductDeliveryDate());
  });
  return (
    <div className="container py-3">
      <ConnectedRouter history={appHistory}>
      <ScrollToTop />
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/dostawa" component={Dostawa} />
          <Route path="/ok" component={Okej} />
          <Route path="/error" component={ErrorPage} />
          <Route path="/export" component={Export} />
          <Route path="/regulamin" component={Regulamin} />
          <Route path="/klauzula" component={Klauzula} />
          <Route path="/kontakt" component={Kontakt} />
          <Route path="/zamowienie" component={Form} />
        </Switch>
      </ConnectedRouter>
    </div>
  );
};

export default App;
