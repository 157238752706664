import * as actions from "./OrderActions";
import { ActionType, getType } from "typesafe-actions";
import { List } from "immutable";
import { Product } from "../products/product";
import { OrderProduct } from "./OrderProduct";
import { OrderPersonData } from "./OrderPersonData";
import { Order } from "./Order";
import { Discount } from "./Discount";
import { OrderReport } from "./OrderReport";

export interface IOrderReduxState {
  orderPersonData: OrderPersonData;
  products: List<OrderProduct>;
  orders: List<OrderReport>;
  orderData: Order;
  discount: Discount;
  preSelectedProduct: OrderProduct;
}

export type OrderActions = ActionType<typeof actions>;

export const initialState: IOrderReduxState = {
  orderPersonData: { city: "Łódź" } as OrderPersonData,
  products: List<OrderProduct>(),
  orders: List<OrderReport>(),
  orderData: {} as Order,
  discount: {} as Discount,
  preSelectedProduct: {} as OrderProduct,
};

export function orderReducer(
  state = initialState,
  action: OrderActions
): IOrderReduxState {
  switch (action.type) {
    case getType(actions.setAddProduct): {
      return handleSetAddProduct(state, action.payload.products);
    }
    case getType(actions.setRemoveProduct): {
      return handleSetRemoveProduct(
        state,
        action.payload.productId,
        action.payload.deliveryDateId
      );
    }
    case getType(actions.setOrderPersonData): {
      return handleSetOrderPersonData(state, action.payload.ordererData);
    }
    case getType(actions.setOrderData): {
      return handleSetOrderData(state, action.payload.orderData);
    }
    case getType(actions.setDiscount): {
      return handleSetDiscount(state, action.payload.discount);
    }
    case getType(actions.setExportData): {
      return handleSetExportData(state, action.payload.orders);
    }
    case getType(actions.setPreAddProduct): {
      return handleSetPreAddProduct(
        state,
        action.payload.productId,
        action.payload.productName
      );
    }
    default:
      return state;
  }
}

const handleSetAddProduct = (
  state: IOrderReduxState,
  product: OrderProduct
): IOrderReduxState => {
  return {
    ...state,
    products: List<OrderProduct>([...state.products.toArray(), product]),
  };
};

const handleSetRemoveProduct = (
  state: IOrderReduxState,
  productId: number,
  deliveryDateId: number
): IOrderReduxState => {
  var newList = state.products.filter(
    (x) => !(x.deliveryDate == deliveryDateId && x.productId == productId)
  );
  return {
    ...state,
    products: List<OrderProduct>(newList),
  };
};

const handleSetOrderPersonData = (
  state: IOrderReduxState,
  orderPersonData: OrderPersonData
): IOrderReduxState => {
  return {
    ...state,
    orderPersonData: { ...orderPersonData },
  };
};

const handleSetOrderData = (
  state: IOrderReduxState,
  orderData: Order
): IOrderReduxState => {
  return {
    ...state,
    orderData: { ...orderData },
  };
};

const handleSetDiscount = (
  state: IOrderReduxState,
  discount: Discount
): IOrderReduxState => {
  return {
    ...state,
    discount: { ...discount },
    orderData: { ...state.orderData, discountcode: discount.code },
  };
};

const handleSetExportData = (
  state: IOrderReduxState,
  orders: OrderReport[]
): IOrderReduxState => {
  return {
    ...state,
    orders: List<OrderReport>(orders),
  };
};

const handleSetPreAddProduct = (
  state: IOrderReduxState,
  productId: number,
  productName: string
): IOrderReduxState => {
  return {
    ...state,
    preSelectedProduct: { ...state.preSelectedProduct, productId, productName },
  };
};
