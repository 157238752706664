import {Header} from "./Header"
import {Footer} from "./Footer"

export const  Klauzula :React.FC=props =>{
    return <>
    <Header></Header>
    <main>
    <ol>
    <li>
        Administratorem Pani/Pana danych osobowych jest<br />
        <strong>Krzysztof Cyniak, ul. Rataja 71, 92- 637 Łódź </strong>, z którym możesz się skontaktować:<br /> za
        pośrednictwem poczty elektronicznej –
        choinkilodz@gmail.com ,<br /> pod nr telefonu – +48
        696 038 725.
    </li>
    <li>
        Krzysztof Cyniak, jako
        administrator Pani/Pana danych osobowych,
        przetwarzać będzie następujące kategorie danych:
    </li>
    <ol>
        <li>Imię i nazwisko,</li>
        <li>
            Adres dostawy/faktury obejmujący: ulicę, kod
            pocztowy, miejscowość, kraj, nazwę firmy
        </li>
        <li>Adres e-mail,</li>
        <li>Numer rachunku bankowego,</li>
        <li>Numer telefonu.</li>
        <li>NIP</li>
    </ol>
    <li>
        Wyżej wskazane kategorie danych osobowych
        przetwarzane będą w celu realizacji złożonego
        zamówienia oraz w celach marketingowych na
        podstawie wyrażonych zgód.
    </li>
    <li>
        Dane osobowe Użytkowników będą przechowywane nie
        dłużej niż to będzie konieczne do należytego
        świadczenia usług, a także przez okres
        przedawnienia roszczeń cywilnoprawnych
        przysługujących Administratorowi.
    </li>
    <li>
        Posiada Pani/Pan prawo dostępu do treści swoich
        danych oraz prawo ich sprostowania, usunięcia,
        ograniczenia przetwarzania, prawo do
        przenoszenia danych, prawo wniesienia sprzeciwu,
        prawo do cofnięcia zgody w dowolnym momencie bez
        wpływu na zgodność z prawem przetwarzania,
        którego dokonano na podstawie zgody przed jej
        cofnięciem.
    </li>
    <li>
        Ma Pani/Pan prawo wniesienia skargi do organu
        nadzorczego, gdy uzna Pani/Pan, iż przetwarzanie
        danych osobowych Pani/Pana dotyczących narusza
        przepisy Rozporządzenia.
    </li>
    <li>
        Podanie przez Pana/Panią danych osobowych jest
        dobrowolne, jednakże niepodanie danych osobowych
        uniemożliwi nam przyjęcie i realizację
        zamówienia.
    </li>
</ol>
    </main>
    <Footer></Footer></>;
}