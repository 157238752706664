import { Header } from "./Header";
import { Footer } from "./Footer";
export const Kontakt: React.FC = (props) => {
  return (
    <>
      <Header></Header>
      <main>
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="d-flex justify-content-center">
              <h5>Kontakt z nami</h5>
            </div>
            <p>
              Jeśli potrzebują Państwo dodatkowych informacji, serdecznie
              zapraszamy do kontaktu z nami drogą mailową na adres
              choinkilodz@gmail.com lub pod numerem telefonu – +48 696 038 725.
            </p>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};
