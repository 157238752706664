import { Header } from "./Header";
import { Footer } from "./Footer";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadOrders } from "./business/order/OrderEpics";
import { getOrderList } from "./business/order/OrderSelector";
import {ExportToExcel} from "./ExportToExcel"
export const Export: React.FC = (props) => {
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const orderList = useSelector(getOrderList);

  let exportData = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();

    dispatch(loadOrders(password));
  };
  return (
    <>
      <Header></Header>
      <main>
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="d-flex justify-content-center">
              <input
                type="password"
                value={password || ""}
                onChange={(e) => {
                  setPassword(e.currentTarget.value);
                }}
              ></input>
              <button
                onClick={(e) => {
                  exportData(e);
                }}
              >
                Zaloguj
              </button>
              <ExportToExcel apiData={orderList.toArray()} fileName="export" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="d-flex justify-content-center">
              <table className="table">
                <thead>
                  <tr>
                    <th>Id zamówienia </th>
                    <th>Data zamówienia</th>
                    <th>Nazwa Kupującego</th>
                    <th>Adress </th>
                    <th>Produkt </th>
                    <th>Ilość</th>
                    <th>Data Dostawy</th>
                    <th>Id Cashbill</th>
                    <th>Kod zniżowy</th>
                    <th>Wartość zamówienia</th>
                    <th>Wartość po kodzie rabatowym</th>
                  </tr>
                </thead>
                <tbody>
                  {orderList.map((x) => (
                    <tr>
                      <td>{x.orderId}</td>
                      <td>{x.orderDate}</td>
                      <td>{x.clientname}</td>
                      <td>{x.adress}</td>
                      <td>{x.product}</td>
                      <td>{x.ile}</td>
                      <td>{x.deliverydate}</td>
                      <td>{x.paymentref}</td>
                      <td>{x.discountcode}</td>
                      <td>{x.total}</td>
                      <td>{x.finalprice}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};
