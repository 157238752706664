import React from "react";
import { Link } from "react-router-dom";

export const Header: React.FC = (props) => {
    return (
        <header>
        <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
          <Link to="/" className="d-flex align-items-center text-dark text-decoration-none">
            <img src="/tree.png" width="40" height="32"  className="me-2" />
               <span className="fs-4">Choinki Łódź</span>
          </Link>
    
          <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
            <Link className="me-3 py-2 text-dark text-decoration-none" to="/dostawa">Dostawa</Link>
            <Link className="me-3 py-2 text-dark text-decoration-none" to="/kontakt">Kontakt</Link>
            <Link className="me-3 py-2 text-dark text-decoration-none" to="/klauzula">Klauzula&nbsp;Informacyjna</Link>
          </nav>
        </div>
    
        <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
          <img src="/logo.png" className="img-fluid" />
        </div>
    
      </header>
    );
  };
  